import {
  Button,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import {
  RemoteDataCollectionIcon,
  TranscribeIcon,
  TranslateIcon,
  VoiceIcon,
  WritingIcon,
} from "src/images/services";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "#475AA2",
      color: "white",
      padding: 8,
      overflow: "hidden",
      "& .MuiGrid-item": {
        padding: 8,
      },
    },
    button_grid: {
      width: 315,
      "& svg": {
        fill: "rgba(0, 0, 0, 0.87)",
        stroke: "rgba(0, 0, 0, 0.87)",
        width: 40,
        height: 40,
      },
      "& button": {
        margin: 6,
        padding: 18,
        background: "white",
        fontWeight: 500,
        fontSize: "1.2em",
        borderRadius: 12,
        boxShadow:
          "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)",
      },
      "& button.selected": {
        background: theme.palette.primary.main,
      },
      "& button.selected .MuiButton-label": {
        color: "white",
        "& svg": {
          fill: "white",
          stroke: "white",
        },
      },
      "& button img": {
        marginLeft: 10,
        position: "absolute",
        left: 0,
      },
      "& button span": {
        justifyContent: "start",
        "& svg": {
          marginRight: 10,
          maxWidth: 35,
          maxHeight: 45,
          fill: "inherit",
          stroke: "inherit",
        },
      },
    },
    right_pane: {
      position: "relative",
      padding: 16,
      [theme.breakpoints.up("md")]: {
        width: 600,
      },
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
      },
    },
    dot_root: {
      display: "block",
      position: "absolute",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      margin: -8,
    },
    dot: {
      position: "relative",
      borderRadius: 64,
    },
    dot1: {
      width: 37,
      height: 37,
      float: "right",
      right: 30,
      top: 50,
      background: "rgba(187, 243, 223, 0.44)",
    },
    dot2: {
      width: 50,
      height: 50,
      left: 0,
      top: 395,
      background: "#FFECEC",
    },
    dot3: {
      width: 80,
      height: 80,
      borderRadius: 78,
      float: "right",
      right: -40,
      top: 250,
      background: "#FFECEC",
    },
    service_info: {
      "& .MuiTypography-root": {
        maxWidth: "50ch",
      },
    },
  })
);

enum ServicesOfferTypes {
  TRANSLATION = "Translation",
  TRANSCRIPTION = "Transcription",
  WRITING = "Writing & Communications",
  RDC = "Remote Data Collection",
  VOICE = "Voice Recording",
}

export default function ServicesWeOffer({ hire }) {
  const classes = useStyles();
  const [selected, setSelected] = useState(ServicesOfferTypes.TRANSLATION);
  const SelectorButtons = () => {
    return (
      <>
        {Object.values(ServicesOfferTypes).map((k) => {
          const Icon = () => IconForService(k);
          return (
            <Button
              key={k}
              variant="contained"
              className={selected === k ? "selected" : ""}
              onClick={() => setSelected(k)}
            >
              <Icon />
              {k}
            </Button>
          );
        })}
      </>
    );
  };

  const ServiceText = () => TextForService(selected, hire);
  return (
    <div className={classes.root}>
      <Typography
        noWrap
        variant="h3"
        style={{
          textAlign: "center",
          margin: "40px 0px 40px 0px",
        }}
      >
        Services We Offer
      </Typography>
      <div style={{ marginTop: -140 }}>
        <div className={`${classes.dot} ${classes.dot1}`} />
        <div className={`${classes.dot} ${classes.dot2}`} />
        <div className={`${classes.dot} ${classes.dot3}`} />
      </div>
      <Grid
        container
        direction="row"
        style={{
          justifyContent: "center",
          marginBottom: "40px",
        }}
      >
        <Grid item style={{ marginTop: 8 }}>
          <Hidden smDown implementation="css">
            <Grid container direction="column" className={classes.button_grid}>
              <SelectorButtons />
            </Grid>
          </Hidden>
          <Hidden mdUp implementation="css">
            <Grid
              container
              direction="row"
              style={{ width: "unset", justifyContent: "center" }}
              className={classes.button_grid}
            >
              <SelectorButtons />
            </Grid>
          </Hidden>
        </Grid>
        <Grid item style={{ marginTop: "auto", marginBottom: "auto" }}>
          <Paper elevation={5} className={classes.right_pane}>
            <div className={classes.service_info}>
              <ServiceText />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

function IconForService(serv: ServicesOfferTypes) {
  switch (serv) {
    case ServicesOfferTypes.TRANSLATION:
      return <TranslateIcon />;
    case ServicesOfferTypes.TRANSCRIPTION:
      return <TranscribeIcon />;
    case ServicesOfferTypes.WRITING:
      return <WritingIcon />;
    case ServicesOfferTypes.RDC:
      return <RemoteDataCollectionIcon />;
    case ServicesOfferTypes.VOICE:
      return <VoiceIcon />;
  }
}

function DataForService(serv: ServicesOfferTypes) {
  switch (serv) {
    case ServicesOfferTypes.TRANSLATION:
      return {
        intro: `We understand that language matters. Our Providers know that
        translations aren’t merely a matter of “converting” words from one
        language to another. It is a skillful art that forms the basis of
        essential communication for the work you do.`,
        list: [
          "Surveys/Questionnaires",
          "Program Reports",
          "Training Guides",
          "Educational Curriculum",
          "Research",
        ],
        green: (
          <>
            60+ <strong>Languages</strong> 150+ <strong>Translators</strong>
          </>
        ),
        button_text: `Hire Translators`,
      };
    case ServicesOfferTypes.TRANSCRIPTION:
      return {
        intro: `Collecting data and first-hand interviews is hard work. You’ve done
          that part. Our Providers bring the care and attention to detail that
          transcription demands, so you can focus on the next step.`,
        list: [
          "In-depth Interviews",
          "Focus Group Discussions",
          "Media",
          "Documentaries",
          "Video subtitling",
        ],
        green: (
          <>
            50+ <strong>Languages</strong> 100+ <strong>Transcribers</strong>
          </>
        ),
        button_text: "Hire Transcribers",
      };
    case ServicesOfferTypes.WRITING:
      return {
        intro: `Effective writing has a critical role to play in social change from
        conception to impact. With handpicked writers for a variety of
        styles, we know you will meet your wordsmith here.`,
        list: [
          "Research papers",
          "Manuscripts",
          "Grant applications",
          "Social media content",
          "Impact stories",
        ],
        green: (
          <>
            50+ Writers: <strong>Editing, Writing, Proofreading</strong>
          </>
        ),
        button_text: "Hire Writers",
      };
    case ServicesOfferTypes.RDC:
      return {
        intro: `Join the revolution of changing how data is collected - connect with
        trained phone enumerators, skilled in interviewing techniques to
        help you reach your target audience.`,
        green: (
          <>
            To learn more, get in touch:{" "}
            <a
              style={{ color: "unset", textDecoration: "none" }}
              href="mailto:team@fieldscope.in"
            >
              team@fieldscope.in
            </a>
          </>
        ),
        button_text: "",
      };

    case ServicesOfferTypes.VOICE:
      return {
        intro: `The shift towards digital includes finding the right voices to
        communicate your message. Our vocal artists come with varying
        experience to meet your needs for voice-overs in different
        languages.`,
        green: (
          <>
            To learn more, get in touch:{" "}
            <a
              style={{ color: "unset", textDecoration: "none" }}
              href="mailto:team@fieldscope.in"
            >
              team@fieldscope.in
            </a>
          </>
        ),
        button_text: null,
      };
  }
}

const useTextStyles = makeStyles(() =>
  createStyles({
    green: {
      display: "inline",
      background: "#cafcea57",
      padding: 10,
      marginBottom: 5,
    },
    bottom: {
      display: "flex",
      justifyContent: "space-around",
      marginTop: 5,
    },
  })
);

function TextForService(serv: ServicesOfferTypes, hire) {
  const classes = useTextStyles();

  const { intro, list, green, button_text } = DataForService(serv);

  return (
    <>
      <Typography style={{ margin: "auto" }}>{intro}</Typography>
      <br />
      <div style={{ maxWidth: "50ch", margin: "auto", textAlign: "center" }}>
        {green && (
          <Paper className={classes.green} elevation={1}>
            {green}
          </Paper>
        )}
        <Grid container justify="space-evenly" style={{ marginTop: 10 }}>
          <Typography component="div" variant="body1">
            {list &&
              list.map((r, idx) => (
                <Grid key={idx} item style={{ display: "inline-block" }}>
                  <li>{r}</li>
                </Grid>
              ))}
          </Typography>
        </Grid>
      </div>
      <div className={classes.bottom}>
        {button_text && (
          <Button onClick={hire} variant="contained" color="primary">
            {button_text}
          </Button>
        )}
      </div>
    </>
  );
}
