import { Button, Card, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import satvika from "./satvika.jpg";
import rituparna from "./rituparna.jpg";
import bhakti from "./bhakti.jpg";
import tanyaa from "./tanyaa.jpg";

const useStyles = makeStyles(() =>
  createStyles({
    dot: {
      width: 610,
      position: "absolute",
      height: 610,
      left: -248,
      top: 100,
      background: "#EA983F",
      opacity: 0.1,
      borderRadius: 530,
      overflowX: "hidden",
    },
    hero_parent: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      marginBottom: 40,
    },
  })
);

const useHeroDivStyles = makeStyles(() =>
  createStyles({
    hero_root: {
      display: "inline-flex",
      flexWrap: "nowrap",
    },
    card: {
      position: "inherit",
      boxShadow: "0px 0px 20px 5px rgba(0,0,0,0.1)",
      borderRadius: 15,
      opacity: 0.9,
      height: 50,
      display: "inline-table",
      padding: 0,
      marginLeft: (props: any) =>
        props.flip ? props.x * 2 + props.size * 7 : props.x,
      marginTop: (props: any) => props.y,
    },
  })
);

const HeroDiv = (props: any) => {
  const classes = useHeroDivStyles(props);
  const { img, size, name, role, cred, x = 0, flip = false } = props;
  return (
    <div className={classes.hero_root}>
      <div
        style={{
          backgroundImage: `url(${img})`,
          width: size * 10,
          height: size * 10,
          backgroundSize: "cover",
          borderRadius: "50%",
          marginLeft: flip ? -x : "unset",
        }}
      />
      <Card className={classes.card}>
        <Grid container style={{ flexWrap: "nowrap", margin: "2px 10px" }}>
          <Grid
            item
            container
            direction="column"
            style={{ width: "auto", textAlign: "right" }}
          >
            <Grid item>
              <Typography variant="subtitle1">{name}</Typography>
            </Grid>
            <Grid item style={{ marginTop: -10 }}>
              <Typography variant="overline">{role}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              whiteSpace: "nowrap",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <div>{cred}</div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default function Header({ hire }) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid
        item
        sm={12}
        md={6}
        container
        direction="column"
        spacing={4}
        justify="center"
        style={{ margin: 0, textAlign: "center", minHeight: "50vh" }}
      >
        <Grid item>
          <div style={{ display: "inline-block", textAlign: "left" }}>
            <Typography variant="h3" gutterBottom>
              Let's Do Good Better.
            </Typography>
            <Typography
              variant="h5"
              color="secondary"
              style={{ maxWidth: "50ch" }}
              gutterBottom
            >
              Our curated pool of talent can fulfill your on-demand project
              needs and further your social mission.
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <Button
            onClick={hire}
            variant="contained"
            color="primary"
            style={{ fontSize: "1.5em" }}
          >
            Find Provider
          </Button>
        </Grid>
      </Grid>
      <div className={classes.dot} style={{ zIndex: -10 }} />
      <Grid item sm={12} md={6} style={{ overflow: "hidden" }}>
        <div className={classes.hero_parent}>
          <HeroDiv
            name="Satvika"
            cred="MSc in Journalism (Northwestern)"
            role="Transcriber"
            img={satvika}
            x={-200}
            y={150}
            size={18}
          />
          <HeroDiv
            name="Tanyaa"
            cred="Human Rights &amp; Refugee Law "
            role="Writer"
            img={tanyaa}
            x={-150}
            y={100}
            flip
            size={12}
          />
          <HeroDiv
            name="Rituparna"
            cred="Sociologist &amp; Researcher"
            role="Translator"
            img={rituparna}
            x={-50}
            y={20}
            size={17}
          />
          <HeroDiv
            name="Bhakti"
            cred="MA Development (Azim Premji)"
            role="Transcriber"
            img={bhakti}
            x={-160}
            y={100}
            flip
            size={14}
          />
        </div>
      </Grid>
    </Grid>
  );
}
