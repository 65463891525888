import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import {
  bbc,
  essmart,
  idinsight,
  jhu,
  oaf,
  opml,
  ucb,
  udhyam,
  worldbank,
} from "./logos";

// import ucb from "./ucb.svg";
// import bbc from "./bbc.png";
// import jhu from "./jhu.svg";
// import udhyam from "./udhyam.png";
import "./slider.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "1em 2em",
    },
    img: {
      maxWidth: 200,
      maxHeight: 35,
      margin: 10,
      filter: "grayscale(100%)",
    },
    trusted_by: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
  })
);

const _img_list = [
  jhu,
  ucb,
  bbc,
  opml,
  udhyam,
  worldbank,
  oaf,
  idinsight,
  essmart,
];
const img_list = [..._img_list, ..._img_list];

// _img_list.map((i) => import(i).then((i) => img_list.push(i)));
// We need the images twice for the slider

export default function TrustedBy() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid
          item
          sm={12}
          md={2}
          container
          alignItems="center"
          justify="center"
        >
          <Grid item className={classes.trusted_by}>
            <Typography noWrap color="secondary" variant="h4">
              Trusted By
            </Typography>
          </Grid>
        </Grid>
        <Grid item sm={12} md={10} style={{ overflow: "hidden" }}>
          <div className="slider">
            <div className="slide-track">
              {img_list.map((img, idx) => (
                <div key={idx} className="slide">
                  <img
                    // src={`/images/home/trusted_by/${img}`}
                    src={img}
                    className={classes.img}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
