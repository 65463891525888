import { graphql, useStaticQuery } from "gatsby";

import { createStyles, makeStyles, Paper, Typography } from "@material-ui/core";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

const useStyles = makeStyles(() =>
  createStyles({
    post: {
      display: "inline-block",
      minWidth: 300,
      width: 300,
      margin: 15,
      overflow: "hidden",
      "& .post-img": {
        height: 300,
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
      "& .MuiPaper-root": {
        overflow: "hidden",
      },
      "& a": {
        color: "black",
        textDecoration: "none",
      },
      "& .post_title": {
        textAlign: "center",
        padding: "12px",
        display: "-webkit-box",
        height: "4em",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        overflow: "hidden",
        whiteSpace: "normal",
      },
    },
  })
);

export default function Blog() {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query MyQuery {
      allMediumPost {
        nodes {
          title
          mediumUrl
          medium_id
          uniqueSlug
          virtuals {
            previewImage {
              imageId
            }
          }
        }
      }
    }
  `);
  return (
    <div style={{ backgroundColor: "white", padding: "24px 0" }}>
      <Typography
        noWrap
        variant="h2"
        style={{ textAlign: "center", padding: "24px 0px 24px 0px" }}
      >
        Blog
      </Typography>

      <div
        style={{
          display: "flex",
          width: "100%",
          overflow: "scroll",
          whiteSpace: "nowrap",
        }}
      >
        {data?.allMediumPost?.nodes.map((p) => (
          <Paper className={classes.post} key={p.medium_id} elevation={8}>
            <a
              href={`https://medium.com/fieldscope-blog/${p.uniqueSlug}`}
              rel="noopener noreferrer"
              title={p.title}
              target="_blank"
            >
              <img
                width={300}
                height={300}
                style={{ objectFit: "cover" }}
                data-src={`https://miro.medium.com/max/600/${p.virtuals.previewImage.imageId}`}
                className="lazyload"
                alt={p.title}
              />
              <Typography variant="h6" className="post_title" gutterBottom>
                {p.title}
              </Typography>
            </a>
          </Paper>
        ))}
      </div>
    </div>
  );
}
