import { forwardRef, useState } from "react";
import {
  createStyles,
  Grow,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@material-ui/core";
import icon1 from "./icons/job-search.png";
import icon2 from "./icons/network.png";
import icon3 from "./icons/track.png";
import icon4 from "./icons/secure-payment.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette["dark_blue"].default,
      color: "white",
      overflow: "hidden",
    },
    tabs: {
      border: "1px solid #FFFFFF",
      borderRadius: 25,
      display: "inline-flex",
      "& .MuiTabs-indicator": {
        display: "none",
      },
      "& button": {
        fontWeight: "bold",
        padding: "0px 2px 0px 2px",
      },
      "& button.Mui-selected .MuiTab-wrapper": {
        padding: 10,
        borderRadius: 25,
        background: "#EA983F",
      },
    },
    dashes: {
      position: "relative",
      border: "3px dashed #FFFFFF",
      height: 600,
      right: "2950%",
      width: "3000%",
      top: 670,
      [theme.breakpoints.down(380)]: {
        top: 700,
      },
    },
    steps: {
      position: "relative",
      width: "100%",
      overflow: "hidden",
      "& table": {
        marginTop: -600,
        marginBottom: "2em",
        marginLeft: "auto",
        marginRight: "auto",
      },
      "& .icon": {
        backgroundColor: "#fff",
        width: 100,
        height: 100,
        borderRadius: "50%",
        [theme.breakpoints.down("xs")]: {
          width: 50,
          height: 50,
        },
      },
      "& .icon div": {
        width: "50%",
        height: "50%",
        backgroundSize: "contain",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        top: 25,
        [theme.breakpoints.down("xs")]: {
          top: 12.5,
        },
      },
      "& .row": {
        textAlign: "right",
        marginRight: -100,
        marginTop: -50,
        [theme.breakpoints.down("xs")]: {
          width: 50,
          height: 50,
        },
      },
    },
  })
);

enum ProcessType {
  MARKETPLACE = 0,
  MANAGED,
}

export default function Process() {
  const [value, setValue] = useState<ProcessType>(ProcessType.MARKETPLACE);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  const classes = useStyles();

  return (
    <div id="how_it_works" className={classes.root}>
      <Typography
        noWrap
        variant="h3"
        style={{
          textAlign: "center",
          paddingTop: "1em",
        }}
      >
        The Fieldscope Process
      </Typography>

      <div style={{ display: "flex", flexDirection: "column-reverse" }}>
        <Grow
          timeout={value === ProcessType.MARKETPLACE ? 250 : 0}
          in={value === ProcessType.MARKETPLACE}
          mountOnEnter
          unmountOnExit
        >
          <>
            <Steps classes={classes} steps={marketplace_steps} />
            <Typography
              variant="h5"
              style={{ textAlign: "center", margin: "1em" }}
            >
              A self-service experience where you drive your own project
            </Typography>
          </>
        </Grow>
        <Grow
          timeout={value === ProcessType.MANAGED ? 250 : 0}
          in={value === ProcessType.MANAGED}
          mountOnEnter
          unmountOnExit
        >
          <>
            <Steps classes={classes} steps={managed_steps} />
            <Typography
              variant="h5"
              style={{ textAlign: "center", margin: "1em" }}
            >
              An assisted experience with a dedicated Fieldscope Manager
            </Typography>
          </>
        </Grow>

        <div style={{ textAlign: "center" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            centered
            className={classes.tabs}
          >
            <Tab label="Marketplace" />
            <Tab label="Managed" />
          </Tabs>
        </div>
      </div>
    </div>
  );
}

const marketplace_steps = [
  [
    icon1,
    "Post your Requirements",
    "Sign-up and tell us what you need to get done",
  ],
  [
    icon2,
    "Find your Match",
    "Browse our top talent and shortlist the right providers to kick off your project",
  ],
  [
    icon3,
    "Collaborate & Track",
    "Assign, track and review your work through our customized tools",
  ],
  [
    icon4,
    "Make Secure Payment",
    "Receive professional invoices and make your payments securely to your service providers",
  ],
];
const managed_steps = [
  [
    icon1,
    "Post your Requirements",
    "Sign-up and tell us what you need to get done",
  ],
  [
    icon3,
    "Dedicated Manager",
    "A manager will understand your requirements and work with a curated team to deliver your work",
  ],
  [
    icon4,
    "Approve & Pay",
    "Once you receive and approve the work, make a payment to Fieldscope",
  ],
];

const Steps = forwardRef((props: any, ref) => {
  const { classes, steps, ...others } = props;
  return (
    <div ref={ref} className={classes.steps} {...others}>
      <table>
        <thead>
          <tr>
            <td>
              <div className={classes.dashes} />
            </td>
          </tr>
        </thead>
        <tbody>
          {steps.map(([icon, title, text], idx: any) => (
            <tr key={idx}>
              <td>
                <div className="icon">
                  <div
                    style={{
                      background: `center / contain no-repeat url(${icon})`,
                    }}
                  />
                </div>
              </td>
              <td align="center">
                <Typography
                  align="center"
                  component="div"
                  variant="h1"
                  style={{
                    margin: "10px",
                    fontWeight: 700,
                  }}
                >
                  {idx + 1}
                </Typography>
              </td>
              <td>
                <div
                  style={{
                    textAlign: "left",
                    maxWidth: "50ch",
                  }}
                >
                  <Typography
                    component="span"
                    variant="h4"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography variant="body1">{text}</Typography>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});
