import Header from "src/components/home/header";
import ServicesWeOffer from "src/components/home/services";
import Blog from "src/components/home/blog";
import Footer from "src/components/home/footer";
import OurUsers from "src/components/home/our_users";
import Process from "src/components/home/process";
import TrustedBy from "src/components/home/trusted_by";
import TopBar from "src/components/topbar";
import { navigateToApp } from "src/redirect_to_app";
import { userStatusQuery } from "src/actions/user";
import { navigate } from "gatsby";
export default function Index() {
  const { data } = userStatusQuery();
  const logged_in = data?.success && data?.user;

  function hire() {
    if (logged_in) {
      navigateToApp();
    } else {
      navigate("/signup");
    }
  }

  return (
    <>
      <TopBar />
      <div style={{ marginTop: 43 }}>
        <Header hire={hire} />
        <ServicesWeOffer hire={hire} />
        <TrustedBy />
        <Process />
        <OurUsers />
        <Blog />
        <Footer />
      </div>
    </>
  );
}
