import { Button, Container, createStyles, Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import { FormatQuote } from "@material-ui/icons";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        slider: {
            textAlign: 'center',
            '& .slick-prev:before, .slick-next:before': {
                color: '#000000de',
                fontSize: 30
            }
        },
        slide: {
            margin: 12,
            display: 'inline-block',
            maxWidth: 500,
            borderRadius: 12,
            padding: 12
        }
    }))


export default function OurUsers() {
    const classes = useStyles()
    return <div style={{ backgroundColor: '#FFF4E8', padding: 16 }}>

        <Typography variant="h3" style={{
            textAlign: 'center', padding: '24px 0px 24px 0px'
        }}>
            Our Clients Love Us!
        </Typography>

        <Container >
            <Slider {...{
                arrows: true,
                dots: false,
                className: classes.slider,
                dotsClass: "our-users-thumb-dots",
                infinite: true,
                speed: 500,
                customPaging: i => <Button>{i}</Button>
            }}>
                {testimonials.map(([name, title, text], idx) => <div key={idx}>
                    <Paper className={classes.slide} elevation={3}>
                        <FormatQuote style={{ fontSize: '4em', textAlign: 'left' }} />
                        <Typography variant="body1" gutterBottom>
                            {text}
                        </Typography>
                        <Typography color="secondary" variant="h5" style={{ marginTop: '1em' }}>
                            {name}
                        </Typography>
                        <Typography variant="subtitle1" >
                            {title}
                        </Typography>
                    </Paper>
                </div>)}
            </Slider>

        </Container>
    </div >
}


const testimonials = [
    [
        "Emily",
        "PhD Candidate, Duke University",
        "Fieldscope is filling a major gap by connecting researchers to pre-screened research support. I haven't seen this service anywhere else and it saves the client A LOT of time.",
    ],
    [
        "Vidushi",
        "Evidence for Policy Design (EPoD) at IFMR",
        "Could get in touch with a large pool of translators in a quick and an efficient way unlike having to engage in multiple emails. The platform was super smooth, user friendly and easy to use",
    ],
    [
        "Pratima",
        "Oxford Policy Management",
        "Super simple processes - I had a great experience using the site."
    ]
]